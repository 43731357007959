$(document).on('change', '.status-lock-and-unlock-toggle', (e) => {
  $(e.target).closest(".bootstrap-toggle-wrapper").find("input[name='user[locked_out]']").val($(e.target).prop('checked') ? 1 : 0)
})

$(document).on('click', '.edit-user-btn', (e) => {
  let user_id = e.target.closest('button').getAttribute('data-target').split('_').at(-1);
  $(`#user_status_toggle_${user_id}`).bootstrapToggle({
    on: 'Lock',
    off: 'Unlock',
    width: '100%',
    size: 'small',
    height: 'match-parent',
    style: 'rounded'
  });
  let toggle_value = $(`#user_status_toggle_${user_id}`)[0].getAttribute('locked_out') === 'true' ? '1' : '0'
  $(`#user_status_toggle_${user_id}`).closest(".bootstrap-toggle-wrapper").find("input[name='user[locked_out]']").val(toggle_value);
})
