document.addEventListener('DOMContentLoaded', () => {
  $('#foreign-salary-month').select2();
  $('#select_foreign_salary_month').datetimepicker({
    format: window.MONTH_YEAR_FORMAT,
    viewMode: 'months',
    date: moment($('#select_foreign_salary_month').data('default')),
    maxDate: moment($('#select_foreign_salary_month').data('max-month')),
    minDate: moment($('#select_foreign_salary_month').data('min-month')),
    icons: {
      previous: "fas fa-chevron-left",
      next: "fas fa-chevron-right",
    }
  });

  render_foreign_salary_table();
});

const change_table_header = (month_year_greater_than_usd_start_date) => {
  if (month_year_greater_than_usd_start_date) {
    $('#current_foreign_salary').html(`Current Foreign Salary </br> (USD)`);
  } else {
    $('#current_foreign_salary').html(`Current Foreign Salary </br> (PKR)`);
  }
}

const remove_marked_as_paid_btn = (current_salary_month) => {
  if (new Date($('#foreign-salary-month').find(':selected').text()).getTime() == new Date(current_salary_month).getTime()) {
    $('#mark-all-as-paid-btn').removeClass('d-none');
  } else if(!$('#mark-all-as-paid-btn').hasClass('d-none')) {
    $('#mark-all-as-paid-btn').addClass('d-none');
  }
}

const remove_loans_data_from_table = (loans_class, closing_balance_class) => {
  $(loans_class).each((index, loan) => {
    if ($(closing_balance_class)[index].innerHTML != '-') {
      loan_amount = parseFloat(loan.innerHTML.replace(/,/g , ''));
      closing_balance_without_loan = parseFloat($(closing_balance_class)[index].innerHTML.replace(/,/g , ''));
      $(closing_balance_class)[index].innerHTML = (loan_amount + closing_balance_without_loan).toLocaleString();
    } else {
      $(closing_balance_class)[index].innerHTML = '-'
    }
    loan.innerHTML = '-';
  });
}

const render_foreign_salary_table = (data_with_loans = true) => {
  if(window.location.href.split('/').at(-1) === 'foreign_salaries') {
    $('#foreign-salary-table').DataTable().destroy();
    $('#foreign-salary-table').DataTable({
      lengthMenu: [[10, 50, 100, 500], [10, 50, 100, 500]],
      pageLength: 100,
      bDestroy: true,
      fixedHeader: true,
      processing: true,
      serverSide: true,
      ajax: {
        'type': 'GET',
        'url': '/foreign_salaries',
        'dataType': 'json',
        'data': (data) => {
          data.page = parseInt($('#foreign-salary-table').DataTable().page.info().page) + 1
          data.per_page = $('#foreign-salary-table').DataTable().page.info().length
          data.search = data.search.value
          data.month_year = $('#foreign-salary-month').find('option:selected').text()
          data.business_unit_id = $('#select-business-unit-filter').val();
          data.order = $('#foreign-salary-table').DataTable().order()[0][0]
          data.direction = $('#foreign-salary-table').DataTable().order()[0][1]
          data.data_with_loans = data_with_loans === true
        }
      },
      "drawCallback": () => {
        if($('#foreign-salary-table').DataTable().rows().count()){
          $('#foreign-salary-table tr:last').attr('id','net-total-row');
        }
      },
      deferRender: true,
      columnDefs: [
        {
        'targets': 1,
        'createdCell': (td, cellData, rowData, row, col) => {
          if(rowData[11]){
            $(td).closest('tr').attr({
              'class': 'cursor-pointer',
              'data-toggle': 'modal',
              'data-target': `#foreign_salary_breakdown_${rowData[11].foreign_salary_log}`
            })
            change_table_header(rowData[11].month_year_greater_than_usd_start_date)
            remove_marked_as_paid_btn(rowData[11].current_salary_month)
          }
         }
        },
        {
          'targets': 6,
          'bSortable': true,
          'createdCell': (td, cellData, rowData, row, col) => {
            $(td).addClass('loan_in_pkr');
          }
        },
        {
          'targets': 7,
          'bSortable': true,
          'createdCell': (td, cellData, rowData, row, col) => {
            $(td).addClass('loan_in_usd');
          }
        },
        {
          'targets': 8,
          'bSortable': true,
          'createdCell': (td, cellData, rowData, row, col) => {
            if(rowData[11]){
              $(td).addClass(`${rowData[11].employee_id}_closing_balance_in_pkr`)
            }
            $(td).addClass('closing_balance_in_pkr');
          }
        },
        {
          'targets': 9,
          "bSortable": true,
          'createdCell': (td, cellData, rowData, row, col) => {
            if(rowData[11]){
              $(td).addClass(`${rowData[11].employee_id}_closing_balance_in_usd`)
            }
            $(td).addClass('closing_balance_in_usd');
          }
        },
        {
          'targets': -1,
          'createdCell': (td, cellData, rowData, row, col) => {
            $(td).addClass('disable-propagation')
          }
        }
      ]
    });
    debounceSearch('#foreign-salary-table');
  }
}

const fetch_dollar_rate = () => {
  let business_unit_id = $('#select-business-unit-filter').val();
  if (window.location.pathname.split('/')[1] == 'foreign_salaries') {
    $.ajax({
      type: 'GET',
      url: '/foreign_salaries',
      dataType: 'script',
      data: { month_year: $('#foreign-salary-month').find('option:selected').text(), business_unit_id: business_unit_id }
    });
  }
}

$(document).on('change', '#foreign-salary-month, #select-business-unit-filter', () => {
  render_foreign_salary_table();
  $('#data-without-loans').prop( "checked", true );
  fetch_dollar_rate();
});

$(document).on('change', '#data-without-loans', (e) => {
  if($(e.target).prop('checked')) {
    render_foreign_salary_table();
  } else {
    render_foreign_salary_table(false);
  }
});

$(document).on('click', '#pkr_currency_type', (e) => {
  let employee_id = e.target.getAttribute('employee-id');
  let closing_balance_in_pkr = $(`#${employee_id}_payable_balance_in_pkr`).html()
  $(`#${employee_id}-closing-balance-column`).html(
    `<b class='text-primary'> Payable Balance: ${closing_balance_in_pkr} </b>`
  );
});

$(document).on('click', '#usd_currency_type', (e) => {
  let employee_id = e.target.getAttribute('employee-id');
  let closing_balance_in_usd = $(`#${employee_id}_payable_balance_in_usd`).html();
  if(closing_balance_in_usd === '-') {
    closing_balance_in_usd = (0).toFixed(2)
  }
  $(`#${employee_id}-closing-balance-column`).html(
    `<b class='text-primary'> Payable Balance: ${closing_balance_in_usd} </b>`
  );
});
