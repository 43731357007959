document.addEventListener('DOMContentLoaded', () => {
  $('#department-options').prepend($('<option>', { value: 'all', text: 'Select All'}));
  initialize_department_and_designation_options();
  set_month_wise_gross_salary_graph($('.month-wise-gross-salary-filter').val());
  initialize_activity_related_to_graph();
});

$(document).on('change', '.month-wise-gross-salary-filter', (e) => {
  set_month_wise_gross_salary_graph(e.currentTarget.value);
})

$(document).on('reset', '#data_insight_filters_form', (e) =>{
  hide_and_clear_dropdown($('#allowances_dropdown'));
  hide_and_clear_dropdown($('#claim_types_dropdown'));
  initialize_activity_related_to_graph();
  e.preventDefault()
})

const initialize_department_and_designation_options = () => {
  $('#department-options').on('select2:select', function (e) {
    if (e.params.data.id === 'all') {
      $('#department-options').val(['all']).change();
        var allOptions = $(this).find('option').not(':disabled');
        var valueArray = $.map(allOptions, function(option) {
            return option.value;
        });
        var filter_options = valueArray.filter(function(e) { return e !== 'all' })
        $(this).val(filter_options);
        $(this).find("option[value='all']").attr('disabled', true)
        render_designation_options(filter_options)
    }
    render_designation_options($('#department-options').val())
  });

  $('#designation-options').on('select2:select', function (e) {
    if (e.params.data.id === 'all') {
      $('#designation-options').val(['all']).change();
        var allOptions = $(this).find('option').not(':disabled');
        var valueArray = $.map(allOptions, function(option) {
            return option.value;
        });
        $(this).val(valueArray.filter(function(e) { return e !== 'all' }));
        $(this).find("option[value='all']").attr('disabled', true)
    }
  });

  $('#department-options').on('select2:unselect', function (e) {
    $(this).find("option[value='all']").attr('disabled', false)
    if (e.params.data.id === 'all') {
      $('#department-options').val('').change();
    }
    render_designation_options($('#department-options').val())
  });
  $('#designation-options').on('select2:unselect', function (e) {
    $(this).find("option[value='all']").attr('disabled', false)
    if (e.params.data.id === 'all') {
      $('#designation-options').val('').change();
    }
  });
}

const render_designation_options = (selected_departments) => {
  if( window.location.href.split('/').at(-1) === 'data_insights'){
    $.ajax({
      type: 'GET',
      url: '/data_insights',
      dataType: 'script',
      data: { department_ids: selected_departments, get_active_designations: true }
    })
  }
}

const initialize_activity_related_to_graph = () => {
  if( window.location.href.split('/').at(-1) === 'data_insights'){
    $('#activity_related_to').val('Net Salary').change();
    $('#designation-options').val('').change();
    var allOptions = $('#department-options').find('option').not(':disabled');
    var valueArray = $.map(allOptions, function(option) {
        return option.value;
    });
    valueArray = valueArray.slice(0,10)
    $('#analytics_from').val($('#analytics_from').data('defualt-fiscal-year-start'))
    $('#analytics_till').val($('#analytics_till').data('defualt-fiscal-year-end'))
    $('#department-options').val(valueArray.filter(function(e) { return e !== 'all' })).trigger('change');
    render_designation_options($('#department-options').val())
    $('#apply-data_insight_filters').click();
  }
}

const set_month_wise_gross_salary_graph = (month_year) => {
  if( window.location.href.split('/').at(-1) === 'data_insights'){
    $.ajax({
      type: 'GET',
      url: '/data_insights',
      dataType: 'script',
      data: { month_year: month_year, chart_type: 'Month-wise Gross Salary' }
    })
  }
}
