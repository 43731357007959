window.MONTH_YEAR_FORMAT = 'MMM YYYY';
window.DATE_FORMAT = 'DD MMM, YYYY';

window.append_errors_inside_modal = (errors_list, header_message, element) => {
  $(element).removeClass("d-none");
  $(element).html(header_message);
  for (let error of Array.from(errors_list)) {
    const error_message = `<div class='text-danger px-5'> \
                              <ul>
                                <li> ${
                                  error.charAt(0).toUpperCase() + error.slice(1)
                                } </li> \
                              </ul>\
                           </div>`;
    $(element).append(error_message);
  }
};

window.append_success_message = (success_list, header_message, element) => {
  const success_message =`<div class='alert alert-success w-100'>\
                          <h6> ${header_message} </h6>` +
                          success_list +
                          "</div>";
  element.removeClass("d-none");
  element.html(success_message);
};

window.adjust_comment_position = (mutations) => {
  mutations.forEach((mutation) => {
    if (mutation.type == 'childList') {
      let id = $('#comment-box-modal').data('id')
      let addedNodes = mutation.addedNodes
      addedNodes.forEach((node) => {
        if ($(node).hasClass(`comment-${id}`)) {
          $(node).addClass('justify-content-end')
          $(node).find('.comment-section').removeClass('bg-light-grey')
          $(node).find('.comment-section').addClass('bg-primary text-white')
        }
      })
      $('.comment-message').trigger('focus');
    }
  })
};

$(document).on("mouseenter", ".nav-dropdown-item", (e) => {
  $(e.target).removeClass("text-light");
});

$(document).on("mouseleave", ".nav-dropdown-item", (e) => {
  $(e.target).addClass("text-light");
});

window.apply_custom_date_input = () => {
  $(".custom-date-input").datetimepicker({
    format: window.DATE_FORMAT,
    icons: {
      previous: "fas fa-chevron-left",
      next: "fas fa-chevron-right",
    },
  });
};

document.addEventListener('DOMContentLoaded', () => {
  $('.select-option').select2({ theme: 'bootstrap4' });
  apply_custom_date_input();
  $('#new-employees-table').DataTable({
    scrollY: true,
    createdRow: function( row, data, dataIndex, columns ) {
      $(row).addClass('employee-row cursor-pointer') ;
      $(columns[0]).addClass('pl-5');
    }
  });
  $('.scrollable-datatable').not('.dataTable').DataTable({ scrollY: true });
  $('.fixedheader-datatable').DataTable({
    fixedHeader: true,
    pageLength: 100
  });
  set_header_width();
  set_scrollableBody_width();
  let top_height = ($('.dataTables_scrollHead').length) ? $('.dataTables_scrollHead').offset().top : 0;
  $('#new-employees-table').closest('.dataTables_scrollBody').height(`calc(100vh - ${top_height + 0.7 * top_height}px)`);
  $('#new-employees-table').closest('.dataTables_scrollBody').addClass('dashboard-datatable');
  $('.scrollable-datatable').on('draw.dt', () => {
    set_header_width();
    set_scrollableBody_width();
    adjust_allowances_datatable();
  })
  $('.scrollable-datatable').closest('.dataTables_scrollBody').addClass('scrollable-max-height')
  $('.dataTable').on('page.dt', () => { $('.dataTables_scrollBody').animate({ scrollTop: 0 }, 'fast') });
  $('.select_time_zone').select2({ theme: 'bootstrap4', width:'100%' })
  $('.btn-disabled').prop('disabled', true);
  fade_alert_container();
});

window.append_avatar = (obj) => {
  data = $(obj.element).data();
  text = $(obj.element).text();
  if (data && data["img_src"]) {
    img_src = data["img_src"];
    template = $(`<div class='row p-0 ml-2 d-flex flex-nowrap'> \
                  <img class = "rounded-circle avatar-size-select-box" src = "${img_src}" "> \
                  <div class = "d-flex align-self-center ml-2 select-box-font-size"> ${text}</div></div>`);
    return template;
  }
};

window.select2_avatar_options = () => {
  const options = {
    templateSelection: function (data) {
      return data.id === "" ? "Please Select Employees" : append_avatar(data);
    },
    templateResult: append_avatar,
  };
  $(".select-employees").select2(options);
};

$(document).on('click', '.destination-page', (e) => {
  let path = $(e.target.parentElement).attr('data');
  window.location = path
});

$(document).on('click', '.employee-profile-page', (e) => {
  e.stopPropagation();
  let path = $(e.target).attr('data');
  window.open(path, '_blank');
});

$(window).resize(() => {
  set_scrollableBody_width();
  adjust_allowances_datatable();
})

$(window).on('scroll', () => {
  $('.dtfh-floatingparent').css('height', 'fit-content')
  $('.dtfh-floatingparent').css('width', $('.dataTables_wrapper').width());
})

var set_header_width = () => {
  $('.dataTables_scrollHeadInner').css('width', '100%')
  $('.dataTables_scrollHeadInner').find('.dataTable').css('width', '100%')
}

var set_scrollableBody_width = () => {
  $('.dataTables_scrollBody').find('.scrollable-datatable').width($('.dataTables_scrollBody').width())
}

$(document).on('shown.bs.modal', (e) => {
  $('input[name="foreign_salary_payment_detail[amount]"]').trigger('focus')
  $('form :input:not([readonly]):visible:enabled:first', e.target).trigger('focus');
  $('.comment-message').trigger('focus');
});

$(document).on('keypress', '.bootstrap-toggle-wrapper', (e) => {
  if (e.key === ' ' || e.key === 'Spacebar') {
    e.preventDefault();
    let checkbox = $('input[type="checkbox"]:first', e.target);
    checkbox.bootstrapToggle('toggle');
  }
});

$(document).on('select2:open', () => {
  document.querySelector('.select2-search__field').focus();
});

$(document).on('shown.bs.modal', (e) => {
  $('body').css('overflow', 'hidden');
});

$(document).on('hidden.bs.modal', (e) => {
  $('body').css('overflow', 'auto');
});

show_alert_container = () => {
  $('.alert-container').css('display', 'block');
  fade_alert_container();
}

fade_alert_container = () => {
  $(".alert-container").fadeTo(5000, 1000).slideUp(1000, function() {
    $(".alert-container").slideUp(1000);
  });
}

$(document).on('click', '.disable-propagation', (e) => {
  e.stopPropagation();
});

$(document).on('click', '.mark-as-read', (e) => {
  let target_item = $(e.currentTarget)
  let notification_id = target_item.attr('data-notification-id')
  $.ajax({
    type: 'Put',
    url: `/notifications/${notification_id}`,
    success(response) {
      let marked_as_read_item = $(`.mark-as-read[data-notification-id=${response.notification_id}]`)
      marked_as_read_item.removeClass('bg-grey cursor-pointer mark-as-read')
      let notifications_count_element = $('.no-of-notifications')[0].innerHTML
      let no_of_notifications = parseInt(notifications_count_element)
      if (no_of_notifications > 1) {
        $('.no-of-notifications')[0].innerHTML = no_of_notifications - 1
      } else {
        $('.no-of-notifications')[0].innerHTML = ''
      }
    }
  })
})

const get_notifications = (page_no) => {
  $.ajax({
    type: 'Get',
    url: '/notifications',
    data: { page_no: page_no },
    success(response) {
      $('.notification-spinner').remove()
      $('.notifications-dropdown').append(response.html)
    }
  })
}

$(document).on('click', '.fa-bell, #notification-dropdown-btn', (e) => {
  $('.notification-spinner').removeClass('d-none')
  get_notifications(1)
})

$(document).on('click', '.see-more', (e) => {
  $('.see-more').remove()
  $('.notification-spinner').removeClass('d-none')
  let page_no = $(e.currentTarget).attr('data-page-no')
  $(e.currentTarget).attr('data-page-no', `${page_no + 1}`)
  get_notifications(parseInt(page_no) + 1)
})

$(document).on('shown.bs.modal', (e) => {
  $(document).on('keyup', (e) => {
    if(e.key == 'Escape') { $('.modal').modal('hide') }
  })
})

$(document).on('shown.bs.tab', (e) => { adjust_allowances_datatable(); });
$(document).on('click', '.dataTables_scrollHead', (e) => { adjust_allowances_datatable(); });

const adjust_allowances_datatable = () => {
  let width = $('#active-allowance-table_wrapper').find('.dataTables_scrollBody').width()
  if (width == 0) {
    width = $('#active-designation-allowance-table_wrapper').find('.dataTables_scrollBody').width()
  }

  if (width == 0 ) { return }

  $('.dataTables_scrollBody').find('.scrollable-datatable').width(width)
}

$(document).on('input', '.amount_format', (e) => {
  current_target = $(e.currentTarget)
  current_target.on('keyup', (e) => {
    return (e.charCode == 8 || e.charCode == 0 || e.charCode == 13) ? null : e.charCode >= 48 && e.charCode <= 57 || e.charCode == 45
  });

  value = current_target.val().replaceAll(',', '')
  if (value == '-') {
    current_target.val(value)
    return;
  }
  isNaN(parseInt(value)) ? current_target.val('') : current_target.val(Number(value).toLocaleString())
});

append_success_message_in_alert_container = (success) => {
  const header_success_message = `<p class='text-success  px-4 pt-4'> <span class='fas fa-check-circle fa-lg mx-2'>\
                                </span><strong> ${success} </strong>\
                                <button class='close' data-dismiss='alert' type='button' id='close-success-alert'> \
                                <span aria-hidden='true'>×</span> \
                                <span class='sr-only'>Close</span> \
                                </button> \
                                </p>`;
  const success_message = `<div class='alert alert-success w-100'>\
                          <h6> ${header_success_message} </h6></div>`
  $('.alert-container').html(success_message);
  show_alert_container();
}

append_errors_list_in_alert_container = (errors) => {
  let error_message = `<p class='text-danger px-4 pt-4'> <span class='fas fa-times-circle fa-lg mx-2'>\
                              </span> <strong>Cannot Add due to following issues:</strong>\
                              <button class='close' type='button' id='close-errors-alert'> \
                                <span aria-hidden='true'>×</span> \
                                <span class='sr-only'>Close</span> \
                              </button> \
                            </p>`;

  for (let error of Array.from(errors)) {
    error_message += `<div class='text-danger px-5'> \
                          <ul>
                            <li> ${error.charAt(0).toUpperCase() + error.slice(1)} </li> \
                          </ul>\
                      </div>`;
  }
  $('.alert-container').removeClass('d-none')
  $('.alert-container').html(`<div class='alert alert-danger w-100'>\
                                <h6> ${error_message} </h6></div>`);
  show_alert_container();
}

$.extend( $.fn.dataTable.defaults, {
  language: {
      "processing": "Loading. Please wait..."
  },
});

window.debounce = (func, timeout = 1000) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

window.debounceSearch = (tableId) => {
  let $searchBox = $(tableId + "_filter input[type='search']");
  $searchBox.off();

  let searchDebouncedFn = debounce(() => {
      $(tableId).DataTable().search($searchBox.val()).draw();
  });

  $searchBox.on("keyup", searchDebouncedFn);
}

window.hide_and_clear_dropdown = (dropdown) => {
  container = dropdown.parent().closest('div')
  dropdown.val('').trigger('change')
  container.addClass('d-none')
}

$(document).on('select2:open', () => {
  document.querySelector('.select2-container--open .select2-search__field').focus();
});

window.initializeSalaryOperationToggles = () => {
  $('.yes-no-toggle').bootstrapToggle({
    on: 'Yes',
    off: 'No',
    width: '100%',
    size: 'small',
    height: 'match-parent',
    style: 'rounded'
  })

  $('.allow-individual-entities-generation-toggle').bootstrapToggle({
    on: 'Entity-wise',
    off: 'Complete Salary',
    width: '100%',
    size: 'small',
    height: 'match-parent',
    style: 'rounded'
  })
}

window.close_modal = (modal) => {
  modal.removeClass('fade')
  modal.modal('toggle');
}
