$(document).on('ajax:error ajax:success', '.app-setting-form', (e) => {
  const { message, status } = e.detail[0];
  if (status == 'ok'){
    append_success_message_in_alert_container(message)
  }
  else{
    append_errors_list_in_alert_container(message)
  }
});

$(document).on('click', '#allowances-settings-tab', (e)=> {
  $.ajax({
    type: 'GET',
    url: `/allowances_reimbursments`,
    dataType: 'JSON',
    success(response){
      $('#allowances-settings').html(response.html);
      $('#allowances_reimbursments_table').dataTable();
      $('.select-option').select2({ theme: 'bootstrap4' });
    },
    error(response) {
      show_header_message_in_alert_container(response.errors);
    }
  });
})

$(document).on('click', '.edit-reibursment-btn', (e) => {
  let reimbursment_id = e.target.getAttribute('reimbursment_id');
  $.ajax({
    type: 'GET',
    url: `/allowances_reimbursments/${reimbursment_id}/edit`,
    dataType: 'JSON',
    success(response){
      $(`#update_reimbursment_${reimbursment_id}`).html(response.reimbursment_form_response);
      $(`#update_reimbursment_${reimbursment_id}`).find('.select-option').select2({
        theme: 'bootstrap4'
      });
    }
  });
})

$(document).on('click', '#add-new-allowance-binding-btn', (e) => {
  $(`#add_new_allowance_reimbursment`).find('.select-option').select2({
    theme: 'bootstrap4'
  });
})

$(document).on('ajax:error ajax:success', '#allowances_reimbursment_form', (e) => {
  let form_action = e.target.getAttribute('action');
  let modal_id = form_action.split('/').length === 3 ? `#update_reimbursment_${form_action.split('/').at(-1)}` : '#add_new_allowance_reimbursment'
  append_message(e, $(modal_id));
});

$(document).on('click', '.delete_reimbursment_btn', (e)=> {
  e.preventDefault();
  let reimbursment_id = e.target.getAttribute('reimbursment_id');
  if (confirm('Are you sure you want to delete this binding?')) {
    $.ajax({
      type: 'DELETE',
      url: `/allowances_reimbursments/${reimbursment_id}`,
      dataType: 'JSON',
      success(response){
        show_header_message_in_alert_container(response.success);
        $('#allowances_reimbursments_table').DataTable().clear().destroy();
        $('#allowances_reibursments_table_body').html(response.reimbursment_table_response);
        $('#allowances_reimbursments_table').DataTable({ fixedheader: true });
        $('#add-new-allowance-binding-btn').html(response.add_allowance_binding_form_response);
      },
      error(response) {
        show_header_message_in_alert_container(response.errors);
      }
    });
  } else {
    return false;
  }
});

const show_header_message_in_alert_container = (message) => {
  const header_success_message = `<p class='text-success px-4 pt-4'> <span class='fas fa-check-circle fa-lg mx-2'></span>
                                    <strong> ${message} </strong>\
                                    <button class='close' data-dismiss='alert' type='button' id='close-success-alert'> \
                                      <span aria-hidden='true'>×</span> \
                                      <span class='sr-only'>Close</span> \
                                    </button> \
                                  </p>`;
  const success_message = `<div class='alert alert-success w-100'><h6> ${header_success_message} </h6></div>`
  $('.alert-container').html(success_message);
  show_alert_container();
}

const append_message  = (e, modal) => {
  const { errors, success } = e.detail[0];
  if (errors.length === 0) {
    close_modal(modal);
    show_header_message_in_alert_container(success);
    if(modal[0].id === 'add_new_allowance_reimbursment') {
      $.ajax({
        type: 'GET',
        url: `/allowances_reimbursments`,
        dataType: 'script'
      })
      $('#add-new-allowance-binding-btn').html(e.detail[0].add_allowance_binding_form_response);
    } else {
      $('#allowances_reibursments_table_body').html(e.detail[0].reimbursment_table_response);
    }
    $('#add_new_allowance_reimbursment').html(e.detail[0].reimbursment_form_response);
  } else if(success.length === 0) {
    append_errors(errors, 'Cannot create allowance reimbursment because', modal)
  }
}

const append_errors = (errors, message, modal) => {
  const header_message = `<p class='text-danger py-4'> <span class='fas fa-times-circle fa-lg mx-2 float-left'></span>
                            <strong class='float-left ml-2'>${message}</strong>\
                            <button class='close mr-3' type='button' id='close-errors-alert'> \
                              <span aria-hidden='true'>×</span> \
                              <span class='sr-only'>Close</span> \
                            </button> \
                          </p>`;
  error_div = modal.find('#ajax_errors')
  error_div.removeClass('d-none')
  error_div.html(header_message);
  for (let error of Array.from(errors)) {
    const error_message = `<div class='text-danger float-left'> \
                              <ul>
                                <li> ${error.charAt(0).toUpperCase() + error.slice(1)} </li> \
                              </ul>\
                          </div>`;
    error_div.append(error_message);
  }
}

$(document).on('click', '.add-exclusive-settings-btn', (e) => {
  $('#exclusive-settings-form').removeClass('d-none');
})

$(document).on('click', '#clear-exclusive-settings-form', (e) => {
  reset_exclusivity_settings_form();
});

$(document).on('click', '.exclusivity-settings-cross-btn', (e) => {
  $('#exclusive-settings-form').addClass('d-none');
  reset_exclusivity_settings_form();
})

const reset_exclusivity_settings_form = () =>{
  $('#exclusivity_setting_inclusive_entity_id').val('').change()
  $('#exclusivity_setting_exclusive_entity_id').val('').change()
}
