import consumer from "./consumer"

jQuery(document).ready(function(){
  consumer.subscriptions.create("SalaryProcessingAlertsChannel", {
    received: (data) => {
      alert = `<div class="alert alert alert-dismissible alert-success text-center" role="alert">` +
                '<button class="close" data-dismiss="alert" type="button">' +
                  '<span aria-hidden="true">×</span><span class="sr-only">Close</span>' +
                '</button>' +
                `${data.message}` +
              '</div>';

      $('.alert-container').html(alert);
      $(".alert-container").fadeTo(5000, 1000).slideUp(1000, function() {
        $(".alert-container").slideUp(1000);
      });
      if (data.event == 'salary_operation') {
        $('.salary-operation-status')[0].innerHTML = `Salary ${data.operation} successfully completed, Refresh Page`
      } else {
        $('.salary-dispatching-status')[0].innerHTML = 'Salary Dispatched Successfully, Refresh Page'
      }
    }
  });
});
