// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://coffeescript.org/

document.addEventListener('DOMContentLoaded', () => {
  if(window.location.pathname.split('/')[1] == 'loans') {
    render_loans_table('#active-loans-table');
    render_loans_table('#inactive-loans-table');
  }
  $('#loan_deduction_month').select2();
  $('#loan_deduction_month_drop').datetimepicker({
    format: window.MONTH_YEAR_FORMAT,
    viewMode: 'months',
    useCurrent: false,
    minDate: $('#loan_deduction_month_drop').val(),
    icons: {
      previous: "fas fa-chevron-left",
      next: "fas fa-chevron-right",
    }
  })
});

const render_loans_table = (table_id) => {
  $(table_id).DataTable({
    lengthMenu: [[10, 50, 100, 500], [10, 50, 100, 500]],
    pageLength: 10,
    bDestroy: true,
    autoWidth: false,
    fixedHeader: true,
    processing: true,
    serverSide: true,
    ajax: {
      'type': 'GET',
      'url': '/loans',
      'dataType': 'json',
      'data': (data) => {
        data.page = parseInt($(table_id).DataTable().page.info().page) + 1
        data.per_page = $(table_id).DataTable().page.info().length
        data.search = data.search.value
        data.business_unit_id = $('#select-business-unit-filter').val();
        data.order = $(table_id).DataTable().order()[0][0]
        data.direction = $(table_id).DataTable().order()[0][1]
        if(table_id === '#inactive-loans-table') { data.inactive_loans = true }
      }
    },
    "drawCallback": () => {
      if($(table_id).DataTable().rows().count()){
        $(`${table_id} tr:last`).attr('id','net-total-row');
      }
    },
    deferRender: true,
    columnDefs: [
      {
        'targets': [1, 2, 3, 4, 5, 6, 7],
        'createdCell': (td, cellData, rowData, row, col) => {
          if(rowData[9]) {
            $(td).attr({
              'class': 'cursor-pointer align-middle',
              'data-toggle': 'modal',
              'data-target': rowData[9].auto_deductible_loan === true ? `#loan_plans_${rowData[9].loan_id}` : `#foreign_salary_loans_payments_${rowData[9].loan_id}`
            })
            $(table_id).append(rowData[9].html);
          }
        }
      },

      {
        'targets': 0,
        'createdCell': (td, cellData, rowData, row, col) => {
          $(td).attr({
            'class': 'align-middle'
          })
        }
      }
    ]
  });
}

$(document).on('change', '#select-business-unit-filter', () => {
  render_loans_table('#active-loans-table');
  render_loans_table('#inactive-loans-table');
});

const apply_bootstrap_toggle = e => {
  $('.paid-by-hand').bootstrapToggle({
    on: 'Yes',
    off: 'No',
    width: '7%',
    size: 'small'
  });
}

const send_ajax_request = (type, url, data, success, error) => {
  $.ajax({
    type,
    url,
    data,
    success(response) {
      return success(response);
    },
    error(response) {
      return error(response);
    }
  });
}

const get_difference_of_loan_amount = e => Number($('#loan_amount').val().replaceAll(',', '')) - get_rows_sum();

const get_table = id => $(id).DataTable();

const get_row_count = e => $('.deduction-value').length;

var get_rows_sum = function(e) {
  let sum = 0;
  $('.deduction-value').each(function() {
    sum += +$(this).val();
  });

  return sum;
};

const getByPayrollRowsSum = () => {
  let sum = 0;
  let byPayrollArray = $('.by-payroll')
  let statusArray = $('.paid')
  for (let i = 0; i < byPayrollArray.length; i++) {
    if (statusArray[i].value !== 'paid') {
      sum += parseInt(byPayrollArray[i].value);
    }
  }
  return sum;
};

const showGenerateSalaryMessage =() => {
  const status = $('.paid')[0].value
  const deductionByPayroll = $('.by-payroll')[0].value
  if ($('.salary_month').val() == 'true' && (status == 'paid_by_hand' && deductionByPayroll > 0) ) {
    $('#message').show();
    $('#message').html(messageForCurrentSalaryMonth);
  } else {
    $('#message').hide();
  }
}

const messageForCurrentSalaryMonth = `<div class='alert alert-warning w-100'><h6 class='pt-2'> \
                                      Please generate salary for this Loan to process in current salary month </h6>`
                                      "</div>";

const add_new_row = (row, month) => {
  send_ajax_request('GET', '/add_new_deduction_row', { row, month },
    (data => {
      $('#add-new-loan-row').before(data);
      apply_bootstrap_toggle();
    }),
    (error => {
      append_errors_inside_modal(['Unsuccessful: Error appending Deduction Plan Row'], '', $('#ajax_errors'))
    })
  );
}

const initializeDatePickers = () => {
  $('#loan-issue-date').datetimepicker( {
    format: window.DATE_FORMAT,
    useCurrent: false,
    icons: {
      previous: "fas fa-chevron-left",
      next: "fas fa-chevron-right",
    },
    minDate: $('#loan-issue-date').data('min-date')
  });
  $('#deduction-start-date').datetimepicker( {
    format: window.MONTH_YEAR_FORMAT,
    viewMode: "months",
    useCurrent: false,
    icons: {
      previous: "fas fa-chevron-left",
      next: "fas fa-chevron-right",
    },
    minDate: $('#deduction-start-date').data('min-date')
  });
}

const hide_loans_input_fields = (input_fields) => {
  input_fields.split(', ').forEach((input_field) => {
    if(!$(input_field)?.hasClass('d-none')) {
      $(input_field)?.addClass('d-none')
    }
  })
}

const hide_and_clear_dropdown = (dropdown) => {
  dropdown.val('').trigger('change');
  dropdown.closest('.col-sm-2').addClass('d-none');
}

$(document).on('change', '.select-employees', (e) => {
  $('#employee_id').val(e.target.value);
})

$(document).on('click', '.manual-repayment-btn', e => {
  let loan_id = e.target.id.split('-').at(-1)
  $(`#manual_loan_repayment_toggle_${loan_id}`).bootstrapToggle({
    off: 'Partial Repayment',
    on: 'Complete Repayment',
    width: '25%',
    size: 'small',
    height: 'match-parent',
    style: 'rounded'
  })
})

$(document).on('click', '#download-loans-csv', (e) => {
  $('#month-year-for-loans-csv').datetimepicker( {
    format: window.MONTH_YEAR_FORMAT,
    viewMode: "months",
    useCurrent: false,
    icons: {
      previous: "fas fa-chevron-left",
      next: "fas fa-chevron-right",
    },
    minDate: $('#month-year-for-loans-csv').data('min-date'),
    maxDate: $('#month-year-for-loans-csv').data('max-date')
  });
})

$(document).on('input', '#loan-exchange-rate-field, #loan-amount-field', (e) => {
  const exchange_rate = $('#loan-exchange-rate').val();
  const amount = $(`#loan_amount`).val();

  $(`#equivalent-pkr-amount`).html((amount * exchange_rate).toFixed(2));
});

$(document).on('change', '.manual-repayment-toggle', (e) => {
  let target_toggle = $(e.target)
  let loan_id = e.target.id.split('_').at(-1)
  let form = target_toggle.parents().find(`.manual-repayment-form-${loan_id}`)
  let total_paybale_amount = form.find('#manually_recievable_amount').attr('data-recievable-amount')
  let amount_field =  form.find('.manual_loan_repayment_amount')
  if(target_toggle.prop('checked')) {
    amount_field.val(total_paybale_amount)
    amount_field.attr('readonly', true)
  } else {
    amount_field.attr('readonly', false)
  }
})

$(document).on('change', '.loan-employee, #loan-issue-date, #monthly-deduction-amount', () => {
  if(($('.loan-employee').length === 0 || $('.loan-employee')[0].value) && $('#deduction-start-date')?.[0]?.value && $('#monthly-deduction-amount')?.[0]?.value) {
    $('.add-deduction-rows')?.addClass('d-none');
    if (JSON.parse($('#select-loan-type').val())[1]) { $('#loan-warning')?.removeClass('d-none');}
  }
});

$(document).on('change', '.paid-by-hand', e => {
  const parent = $(e.target).closest('.row');
  let deductionAmount = parent.find('.amount')
  if (!$(e.target).prop('checked')) {
    deductionAmount.attr('readonly', false);
    parent.find('.paid').val('unpaid');
    parent.find('.by-payroll').val(parseInt(deductionAmount.val()));
    parent.find('#paid-status').text('Unpaid');
    parent.find('.note-field').attr('readonly', true);
    parent.find('.amount-by-hand').attr('readonly', true);
    parent.find('.amount-by-hand').val(0);
    parent.find('.amount').attr('readonly', false);
    $('#ajax_errors').html('');
    const updatedRemainingAmount = getByPayrollRowsSum();
    $('#remaining').val(updatedRemainingAmount);
    $('#save-form').attr('disabled', false);
    showGenerateSalaryMessage();
    return;
  }
  if (confirm('By pressing OK, the amount will not be deducted from his Salary.') && (get_difference_of_loan_amount() === 0)) {
    parent.find('.paid').val('paid_by_hand');
    parent.find('#paid-status').text('Paid by hand');
    parent.find('amount').attr('readonly', true);
    parent.find('.amount-by-hand').attr('readonly', false);
    parent.find('.by-payroll').val(0);
    parent.find('.amount-by-hand').val(parseInt(deductionAmount.val()));
    parent.find('.amount').attr('readonly', true);
    const updatedRemainingAmount = getByPayrollRowsSum();
    $('#remaining').val(updatedRemainingAmount);
    parent.find('.note-field').attr('readonly', false);
    const name = $(e.target).attr('name');
    $('<input />').attr('type', 'hidden').attr('name', name).attr('value', '1').appendTo('#loan_form_id');
    showGenerateSalaryMessage();
    return;
  }

  $(e.target).bootstrapToggle('off');
});

const removeNonDigits = (value) => {
  return value.replace(/\D/g,'')
}

$(document).on('input', '.amount-by-hand', e => {
  let parent = $(e.target).closest('.row');
  let amountByHand = removeNonDigits($(e.target).val())
  $(e.target).val(amountByHand);
  let deductionAmount = parseInt(parent.find('.amount').val());
  let byPayrollAmount = deductionAmount;
  let status = 'Paid by hand';
  let disableSaveForm = true;
  if (amountByHand == "") {
    append_errors_inside_modal(['By hand amount cannot be empty if paid by hand is selected'], '', $('#ajax_errors'));
  } else if (amountByHand > deductionAmount)
  {
    append_errors_inside_modal(['By hand amount cannot be greater than deduction amount'], '', $('#ajax_errors'));
    byPayrollAmount.val(0);
  } else if (amountByHand == 0){
    append_errors_inside_modal(['By hand amount cannot be zero'], '', $('#ajax_errors'));

  } else if (amountByHand == deductionAmount){
    $('#ajax_errors').html('');
    disableSaveForm = false
    byPayrollAmount = deductionAmount - amountByHand;
  } else {
    disableSaveForm = false
    status = 'Partially paid by hand'
    $('#ajax_errors').html('');
    byPayrollAmount = deductionAmount - amountByHand;
  }
  parent.find('.paid').val('paid_by_hand');
  parent.find('#paid-status').text(status);
  parent.find('.by-payroll').val(byPayrollAmount);
  $('#save-form').attr('disabled', disableSaveForm);
  const updatedRemainingAmount = getByPayrollRowsSum();
  $('#remaining').val(updatedRemainingAmount);
  showGenerateSalaryMessage();
})

$(document).on('input', '.amount', e => {
  let parent = $(e.target).closest('.row');
  let deduction_amount = $(e.target).val();
  $(e.target).val(removeNonDigits(deduction_amount))
  parent.find('.by-payroll').val($(e.target).val());
})

$(document).on('change', '#select-loan-type', () => {
  if($('#select-loan-type').val() === '') {
    hide_loans_input_fields(`#create-plans-btn, #create-loans-btn`);
  } else {
    const [ loan_type_id, deduct_from_base_salary, one_time_payment, currency_type ] = JSON.parse($('#select-loan-type').val());
    if(deduct_from_base_salary) {
      $(`#basic_loan_employees, #create-plans-btn, #monthly-deduction-amount-field,
         #monthly-deduction-header, #start-deduction-from, #deduction-start-date-field,
         .add-deduction-rows`).removeClass('d-none');

      hide_loans_input_fields(
        `#create-loans-btn, #manual_loan_payments_form, #exchange-rate-header, #loan-exchange-rate-field, #foreign_salary_loan_employees`
      );
      if (one_time_payment) {
        hide_loans_input_fields(`#monthly-deduction-amount-field, #monthly-deduction-header`);
      }
      hide_and_clear_dropdown($('#employee-foreign_salary-loan'));

    } else {
      hide_loans_input_fields(
        `#create-plans-btn, #monthly-deduction-amount-field, #monthly-deduction-header,
         #start-deduction-from, #deduction-start-date-field, .add-deduction-rows, #loan-warning,
         #start-deduction-from, #exchange-rate-header, #loan-exchange-rate-field, #basic_loan_employees`
      );
      if(currency_type === 'usd') {
        $('#exchange-rate-header, #loan-exchange-rate-field').removeClass('d-none')
      }
      $('#create-loans-btn, #foreign_salary_loan_employees, #manual_loan_payments_form').removeClass('d-none');
      hide_and_clear_dropdown($('#employee-basic-loan'));
    }
  }
});

$(document).on('dp.change', '#loan-issue-date', () => {
  $('#issue-date').val($('#loan-issue-date').val());
})

$(document).on('input', '#maple-ticket-number', () => {
  $('#ticket-number').val($('#maple-ticket-number').val());
})

$(document).on('input', '#loan-description', () => {
  $('#loans-description').val($('#loan-description').val());
})

$(document).on('click', '#create_plans, #update_plans, #create-loan, #update-loan', e => {
  $('#loan-warning').addClass('d-none');
  const [ loan_type_id, deduct_from_base_salary, one_time_payment, currency_type ] = JSON.parse($('#select-loan-type').val());
  const add_new_loan = $(e.target).attr('id') === 'create_plans' || $(e.target).attr('id') === 'create-loan';
  const total_loan_amount = $('#loan_amount').val().replaceAll(',', '');
  const manual_loan_payments_sum = $('#manual_loan_payments_sum').val().replaceAll(',', '');
  const issue_date = $('#loan-issue-date').val();
  const exchange_rate = $('#loan-exchange-rate').val();
  const maple_ticket_number = $('#maple-ticket-number').val();
  const description = $('#loan-description').val();

  let employee = ''
  let start_date = '';
  let deduction = 0;
  let data = {};
  if(deduct_from_base_salary){
    employee = $('#employee-basic-loan').val();
    start_date = $('#deduction-start-date').val() + '-01';
    deduction = (one_time_payment === true) ? total_loan_amount : $('#monthly-deduction-amount').val()
    deduction = parseInt(deduction.replaceAll(',', ''));
    data = { employee_id: employee, total_amount: total_loan_amount, monthly_deduction_amount: deduction, date: start_date,
             loan_type_id: loan_type_id, issue_date: issue_date, ticket_number: maple_ticket_number, description: description };
  } else {
    employee = $('#employee-foreign_salary-loan').val();
    data = {loan: { employee_id: employee, total_loan_amount: total_loan_amount, remaining_loan_amount: total_loan_amount - manual_loan_payments_sum,
                    monthly_deduction_amount: deduction, loan_type_id: loan_type_id, issue_date: issue_date, ticket_number: maple_ticket_number,
                    exchange_rate: exchange_rate, description: description }}
  }
  if ([employee].includes('') || (([start_date].includes('') || isNaN(deduction)) && deduct_from_base_salary) || isNaN(total_loan_amount)) {
    return append_errors_inside_modal(['Please fill all the fields'], '', $('#ajax_errors'));
  } else if (deduction > total_loan_amount && deduct_from_base_salary) {
    return append_errors_inside_modal(['Deduction cannot be greater than Total Loan Amount'], '', $('#ajax_errors'));
  }

  let request = '';
  let url = '';
  if (add_new_loan) {
    request = 'POST';
    url = (deduct_from_base_salary) ? 'loan_deduction_plans' : 'loans';
  } else {
    const loan_id = $('#loan_id').val();
    if(deduct_from_base_salary) {
      employee = $('#employee_id').val();
      request = 'GET';
      url = `/loan_deduction_plans/${loan_id}/edit`;
    } else {
      request = 'PUT';
      url = `/loans/${loan_id}`;
    }
  }

  $.ajax({
    type: request,
    url: url,
    data: data,
    dataType: 'script',
    success(response) {
      $('#ajax_errors').html('');
      $(document).find('.add-deduction-rows').html(response);
      apply_bootstrap_toggle();
      $('.add-deduction-rows').removeClass('d-none');
      const updatedRemainingAmount = getByPayrollRowsSum();
      $('#remaining').val(updatedRemainingAmount);
      if ($('.salary_month').val() == 'true' && add_new_loan) {
        $('#message').show();
        $('#message').html(messageForCurrentSalaryMonth);
      } else {
        $('#message').hide();
      }
      if(!deduct_from_base_salary) { location.reload(); }
    },
    error(error) {
      append_errors_inside_modal(
        [ error.responseText || 'Unsuccessful: Error getting Deduction Plan'], '', $('#ajax_errors')
      );
    }
  });
});

$(document).on('click', '#add-new-loan', e => {
  send_ajax_request('GET', '/loans/new', {},
                    (data => {
                      $('.loan_to_employee').html(data);
                      $('#employee-basic-loan').select2({ theme: 'bootstrap4' });
                      $('#employee-foreign-salary-loan').select2({ theme: 'bootstrap4' });
                      $(document).find('.loan_to_employee').modal('show');
                      select2_avatar_options();
                      initializeDatePickers();
                    }),
                    (error => {
                     append_errors_inside_modal(['Unsuccessful: Error appending Deduction Plan Row'], '', $('.alert-container'))
                    })
                  );
  $('#select-loan-type').select2({ theme: 'bootstrap4' });
});

$(document).on('change', '.loan-employee', (e) => {
 $('#loan_employee_id').val(e.target.value)
});

$(document).on('click', '#edit-loan', e => {
  const deductible = e.target.getAttribute('deduct-from-foreign-salary');
  const one_time_payment = e.target.getAttribute('one-time-payment');
  const description = e.target.getAttribute('description');

  send_ajax_request('GET', `/loans/${e.target.getAttribute('loan_id')}`, {},
                    (data => {
                      $('.loan_to_employee').html(data);
                      $(document).find('.loan_to_employee').modal({show: true, keyboard: false, backdrop: 'static'});
                      apply_bootstrap_toggle();
                      $('#loan-description').html(description);
                      if (deductible.toLowerCase() === 'foreign_salary') {
                        hide_loans_input_fields(`#deduction-start-date-field, #start-deduction-from`);
                        hide_loans_input_fields(`#monthly-deduction-amount-field, #monthly-deduction-header`);
                        $('#create-loans-btn').removeClass('d-none');
                      } else if (deductible.toLowerCase() === 'base_salary') {
                        if (one_time_payment === 'true') {
                          hide_loans_input_fields(`#monthly-deduction-amount-field, #monthly-deduction-header`);
                        }
                        $('#create-plans-btn').removeClass('d-none');
                      }
                      initializeDatePickers();
                    }),
                    (error => {
                      append_errors_inside_modal(['Unsuccessful: Error appending Deduction Plan Row'], '', $('.alert-container'))
                    })
                   )
});

$(document).on('click', '#add-loan-deduction-row', () => {
  const row_count = get_row_count();
  const month = $('.loan-month').last().val();
  add_new_row(row_count, month);
});

$(document).on('input', '.deduction-value, #loan_amount', () => {
  if ($('.deduction-value').length === 0) { return; }

  $('#ajax_errors').html('');
  const difference = get_difference_of_loan_amount();

  if (difference !== 0) {
    append_errors_inside_modal([`Difference of loan amount with sum of its plans is ${difference}. Please adjust unpaid plans!`], '', $('#ajax_errors'));
    $('#save-form').attr('disabled', true);
    $('#add-loan-deduction-row').attr('disabled', false);
  } else {
    $('#save-form').attr('disabled', false);
    $('#add-loan-deduction-row').attr('disabled', true);
  }
});

$(document).on('submit', '#loan_form_id', () => {
  let row_count = get_row_count();
  while ($(`#loan_loan_deduction_plans_attributes_${row_count - 1}_deduction_amount`).val() === '0') {
    $(`#loan_loan_deduction_plans_attributes_${row_count - 1}_deduction_amount`).closest(".row").find('.destroy_field').val('1');
    row_count -= 1;
  }
});

$(document).on('change', '#loans_csv ', (e) => {
  const fileName = $(e.target).val().split("\\").pop();
  if (fileName.replace(/^.*\./, '') === 'csv') {
    $(e.target).siblings(".custom-file-label").addClass("selected").html(fileName);
    $('#import-loans-btn').attr("disabled", false);
  } else {
    $('#import-loans-btn').attr("disabled", true);
    $(e.target).siblings(".custom-file-label").addClass("selected").html(fileName);
    $(e.target).parent().append("<p id='error' style='color: red'>Please choose csv file.</p>");
    setTimeout(() => {
      $('#import-loans-btn').attr("disabled", true);
      $('#error').remove();
    }, 5000);
    e.preventDefault();
  }
});
