const moment = require("moment");

document.addEventListener('DOMContentLoaded', () => {
  initializeDatePicker();
});

const initializeDatePicker = () => {
  document.querySelectorAll('.datepicker').forEach(e => {
    $(e).datetimepicker( {
      format: window.MONTH_YEAR_FORMAT,
      viewMode: "months",
      useCurrent: false,
      minDate: $(e).data('min-date'),
      icons: {
        previous: "fas fa-chevron-left",
        next: "fas fa-chevron-right",
      }
    });
  })
}

$(document).on('ajax:error ajax:success', '#define_percentage_form', (e) => {
  append_message(e, $('#define_percentage'));
  const { errors } = e.detail[0]
  if(errors.length === 0) {
    let selected_index = $('.pf_employees_dropdown').find('option:selected').val();
    if(selected_index) {
      $('.pf_employees_dropdown').find(`[value = ${selected_index}]`).remove();
      $('#define_percentage_form').trigger('reset');
    }
  }
});


$(document).on('ajax:error ajax:success', '#create-multiple-pfs-form', (e) => {
  const { errors, saved_pfs_list } = e.detail[0]

  if(errors.length === 0) {
  show_success_message_in_alert_container();
  }
  else if (errors?.length != 0) {
    show_error_messages_in_modal(errors)
    if(Object.keys(saved_pfs_list)?.length != 0) {
      show_success_message_list_in_modal(saved_pfs_list)
    }
  }
});

const show_error_messages_in_modal = (errors) => {
  const header_error_message = `<p class='text-danger px-4 pt-4'>
                                    <span class='fas fa-times-circle fa-lg mx-2'></span>
                                    <strong>Failed to create PF because of following reasons:</strong>\
                                    <button class='close' type='button' id='close-errors-alert-btn'> \
                                      <span aria-hidden='true'>×</span> \
                                      <span class='sr-only'>Close</span> \
                                    </button> \
                                  </p>`;
    error_div = $('.payroll_sheet_warnings_modal').find('#ajax_errors_for_pfs')
    error_div.removeClass('d-none')
    error_div.html(header_error_message);
    for (let error of Array.from(errors)) {
      const error_message = `<div class='text-danger'> \
                                <ul>
                                  <li> ${error.charAt(0).toUpperCase() + error.slice(1)} </li> \
                                </ul>\
                            </div>`;
      error_div.append(error_message);
    }
}

const show_success_message_list_in_modal = (saved_pfs_list) => {
  const header_success_message = `<p class='text-success  px-4 pt-4'>
                                    <span class='fas fa-check-circle fa-lg mx-2'></span>
                                    <strong> PF of following designations created successfully.</strong>\
                                    <button class='close' data-dismiss='alert' type='button' id='close-success-alert'> \
                                      <span aria-hidden='true'>×</span> \
                                      <span class='sr-only'>Close</span> \
                                    </button> \
                                  </p>`;
  const success_message = `<div class='alert alert-success w-100'>\
                          <h6> ${header_success_message} </h6>`
  $('#ajax_success_for_pfs').html(success_message);
  $('#ajax_success_for_pfs').removeClass('d-none');

  Object.keys(saved_pfs_list).forEach((key) => {
    const desg_title = `<div class='text-success'> \
                              <ul>
                                <li> ${saved_pfs_list[key]} </li> \
                              </ul>\
                          </div>`;
    $('#ajax_success_for_pfs').append(desg_title);
    $(`#pf_row_${key}`).remove();
  })
}

const show_success_message_in_alert_container = () => {
  $('.payroll_sheet_warnings_modal').modal('toggle');
  const header_success_message = `<p class='text-success  px-4 pt-4'> <span class='fas fa-check-circle fa-lg mx-2'>\
                              </span><strong> Provident Funds of all designations created successfully. Please Refresh the Page </strong>\
                              <button class='close' data-dismiss='alert' type='button' id='close-success-alert'> \
                              <span aria-hidden='true'>×</span> \
                              <span class='sr-only'>Close</span> \
                              </button> \
                              </p>`;
  const success_message = `<div class='alert alert-success w-100'>\
                          <h6> ${header_success_message} </h6>`
  $('.alert-container').html(success_message);
  show_alert_container();
}


$(document).on('hidden.bs.modal', '.payroll_sheet_warnings_modal', (e) => {
  location.reload();
});

$(document).on('click', '#close-errors-alert-btn', (e) => {
  e.preventDefault();
  hide_alerts_div_in_modal('#ajax_errors_for_pfs')
})

$(document).on('click', '#close-success-alert', (e) => {
  e.preventDefault();
  hide_alerts_div_in_modal('#ajax_success_for_pfs')
})

$(document).on('ajax:error ajax:success', '#pf_percentage_form', (e) => {
  let id = e.detail[0].pf_bindable_id
  let type = e.detail[0].pf_bindable_type
  if(type === 'Employee') {
    append_message(e, $(`#updateEmployeePF_${id}`))
  } else if(type === 'Designation') {
    append_message(e, $(`#update_designation_pf_${id}`))
  }
});

const hide_alerts_div_in_modal = (alert_div) => {
  $(alert_div).html('')
  $(alert_div).addClass('d-none');
}

const append_message  = (e, modal) => {
  const { errors, success, html, pf_bindable_type} = e.detail[0];
  if (errors.length === 0) {
    $.ajax({
      type: 'GET',
      url: ' /pf_percentages/',
      data: { type: pf_bindable_type},
      dataType: 'script',
    })
    if (modal[0] != $('#define_percentage')[0]) {
      modal.html(html)
    }
    modal.modal('toggle');
    const header_success_message = `<p class='text-success  px-4 pt-4'> <span class='fas fa-check-circle fa-lg mx-2'>\
                                </span><strong> ${success} </strong>\
                                <button class='close' data-dismiss='alert' type='button' id='close-success-alert'> \
                                <span aria-hidden='true'>×</span> \
                                <span class='sr-only'>Close</span> \
                                </button> \
                                </p>`;
    const success_message = `<div class='alert alert-success w-100'>\
                            <h6> ${header_success_message} </h6>`
    $('.alert-container').html(success_message);
    show_alert_container();
    if (!modal.find('#ajax_errors').hasClass('d-none')) {
      modal.find('#ajax_errors').addClass('d-none');
    }
  } else if(success.length === 0) {
    append_errors(errors, 'Cannot create PF due to following reason', modal)
  }
}

const append_errors = (errors, message, modal) => {
  const header_message = `<p class='text-danger px-4 pt-4'> <span class='fas fa-times-circle fa-lg mx-2'>\
                            </span> <strong>${message}</strong>\
                            <button class='close' type='button' id='close-errors-alert'> \
                              <span aria-hidden='true'>×</span> \
                              <span class='sr-only'>Close</span> \
                            </button> \
                          </p>`;
  error_div = modal.find('#ajax_errors')
  error_div.removeClass('d-none')
  error_div.html(header_message);
  for (let error of Array.from(errors)) {
    const error_message = `<div class='text-danger'> \
                              <ul>
                                <li> ${error.charAt(0).toUpperCase() + error.slice(1)} </li> \
                              </ul>\
                          </div>`;
    error_div.append(error_message);
  }
}

$(document).on('click', '.show-revisions-btn', (e) => {
  let pf_bindable_id = $(e.target).data('id')
  let pf_bindable_type = $(e.target).data('class')

  $.ajax({
    type: 'GET',
    url: ' /pf_percentages/revisions',
    data: { pf_bindable_id: pf_bindable_id, pf_bindable_type: pf_bindable_type },
    dataType: 'JSON',
    success(response) {
      $(`#${pf_bindable_type}_pf_revisions_${pf_bindable_id}`).html(response.html);
    }
  })
})

$(document).on('click', '.remove-pf-row', (e) => {
  e.preventDefault();
  let id = e.target.getAttribute('id')
  $(`#pf_row_${id}`).remove();
  if( $('.pf_row').length === 0 ) {
    $('#save-pf-form-btn').prop('disabled', true);
    $('#save-pf-form-btn').css('cursor', 'not-allowed');
  }
})

$(document).on('click', '#define_percentage_btn', (e) => {
  initializeDatePicker();
})

$(document).on('click', "#delete_pf_revision_btn", (e) => {
  e.preventDefault();
  let pfRevisionId = $(e.target).data('pf-revision-id')
  let pfBindableId = $(e.target).data('pf-bindable-id')
  let pfBindableType = $(e.target).data('pf-bindable-type')
  $.ajax({
    type: 'DELETE',
    url: `/pf_percentages/${pfRevisionId}`,
    data: { id: pfRevisionId, pf_percentage: {pf_bindable_id: pfBindableId, pf_bindable_type: pfBindableType}, destroy_pf_revision: 'true' },
    dataType: 'script'
  });
})

$(document).on('click', ".add_revision_btn", (e) => {
  initializeDatePicker();
})

$(document).on('click', "#define_percentage_btn", (e) => {
  select2_avatar_options();
  initializeDatePicker();
});

$(document).on('change.select2', '#select-business-unit-filter', function() { filter_pf_percentage_table() });
const filter_pf_percentage_table = () => {
  let business_unit_id = $('#select-business-unit-filter').val();
  if (window.location.pathname.split('/')[1] == 'pf_percentages') {
    $.ajax({
      type: 'GET',
      url: '/pf_percentages',
      dataType: 'script',
      data: { type: 'Employee', business_unit_id: business_unit_id }
    });
  }
}
