document.addEventListener('DOMContentLoaded', () => {
  $('#claim-table').DataTable({
    fixedHeader: true,
    processing: true,
    serverSide: true,
    ajax: {
      'type': 'get',
      'url': '/claims',
      'dataType': 'json',
      'data': (data) => {
        data.page = parseInt($('#claim-table').DataTable().page.info().page) + 1
        data.per_page = $('#claim-table').DataTable().page.info().length
        data.search = data.search.value
        data.order = $('#claim-table').DataTable().order()[0][0]
        data.direction = $('#claim-table').DataTable().order()[0][1]
        data.status = $('#claim-status').val()
        data.business_unit_id = $('#select-business-unit-filter').val()
      }
    },
    columnDefs: [
      {
        "bSortable": false,
        "aTargets": [ 7 ]
      }
    ]
  });
});

$(document).on('dp.change', '#claims-from-filter, #claims-to-filter', () => { filter_claims() });
$(document).on('change.select2', '#claim-type, #claim-status, #select-business-unit-filter', () => { filter_claims() });

const filter_claims = () => {
  let claim_type_ids = $('#claim-type').val();
  let status = $('#claim-status').val();
  let business_unit_id = $('#select-business-unit-filter').val();
  let pathname = window.location.pathname;
  let employee_id = pathname.split('/')[2];
  let start_date = $('#claims-from-filter').val();
  let end_date = $('#claims-to-filter').val();

  if (pathname.split('/')[1] == 'claims'|| ((pathname.split('/')[1] == 'employees') && employee_id)) {
    $.ajax({
      type: 'GET',
      url: '/claims',
      dataType: 'script',
      data: { claim_type: claim_type_ids, status: status, business_unit_id: business_unit_id, employee_id: employee_id,
              start_date: start_date, end_date: end_date }
    });
  }
}
