document.addEventListener('DOMContentLoaded', () => {
  $('.current-month-year-datepicker').datetimepicker({
    format: "MMM, YYYY",
      viewMode: "months",
      useCurrent: false,
      minDate: $('.current-month-year-datepicker').data('min-date'),
      maxDate: $('.current-month-year-datepicker').val(),
      icons: {
        previous: "fas fa-chevron-left",
        next: "fas fa-chevron-right",
      }
  })
  $('.current-month-year-datepicker').val('')
});
