import consumer from "./consumer"

jQuery(document).ready(function(){
  consumer.subscriptions.create("AlertsChannel", {
    received: (data) => {
      $('#fetch-data-btn').prop('disabled', false);
      let alert_class = '';
      let message = '';

      if (data.error) {
        alert_class = 'alert-danger';
        message = data.error;
      }
      else {
        alert_class = 'alert-success';
        message = data.message;
        $('#fetched-entries').text(data.fetched_entries)
        $('#updated-entries').text(data.updated_entries)

        if(data.employee_rows.length > 0) {
          $('#new-employees-table').dataTable().fnAddData(data.employee_rows);
        }
      }

      alert = `<div class="alert alert alert-dismissible ${alert_class} text-center" role="alert">` +
                '<button class="close" data-dismiss="alert" type="button">' +
                  '<span aria-hidden="true">×</span><span class="sr-only">Close</span>' +
                '</button>' +
                `${message}` +
              '</div>';

      $('.alert-container').html(alert);
      show_alert_container();
    }
  });
});
