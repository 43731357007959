document.addEventListener('DOMContentLoaded', () => {
  initialize_datepicker()
  render_leaves_table()
  $('#select_leaves_csv_month').datetimepicker({
    format: window.MONTH_YEAR_FORMAT,
    viewMode: 'months',
    maxDate: moment($('#select_leaves_csv_month').data('max-month')),
    minDate: moment($('#select_leaves_csv_month').data('min-month')),
    icons: {
      previous: "fas fa-chevron-left",
      next: "fas fa-chevron-right",
    }
  });
})

$(document).on('shown.bs.modal', '.leave_details_modal', (e) => {
  let leave_id = e.target.dataset['leaveId']
  $.ajax({
    type: 'GET',
    url: `/leaves/${leave_id}`,
    dataType: 'script',
  });
})

$(document).on('shown.bs.modal', '.leave_factors_modal', (e) => {
  let dropdown = $(e.target).find('#select_leave_type')
  dropdown.select2({ theme: 'bootstrap4' });
  render_leave_factor_form(dropdown)
})

$(document).on('hidden.bs.modal', '.leave_factors_modal', (e) => {
  $('.compensation-type-field').html('')
  $('.leave-factor-form').html('')
})

$(document).on('change', '#select_leave_type', (e) => {
  let dropdown = $(e.target)
  render_leave_factor_form(dropdown)
})


$(document).on('ajax:error ajax:success', '#leave-factors-form', (e) => {
  append_messages_and_refresh_leave_type_table(e, $('.leave_factors_modal'));
});

const append_messages_and_refresh_leave_type_table = (e, modal) =>{
  const { message, status } = e.detail[0];
  if (status == 'ok') {
    append_success_message_in_alert_container(message);
    modal.find('form').trigger('reset');
    modal.modal('hide');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
    refresh_leave_types_table();
  }
  else{
    append_errors_list_in_alert_container(message)
  }
}

$(document).on('change', '#select-business-unit-filter, #leave-type-filter, #leave-status-filter, #leave-payroll-status-filter', () => {
  render_leaves_table()
});

$(document).on('dp.change', '#leaves-from-filter, #leaves-to-filter', () => {
  render_leaves_table()
})

if (window.location.href.split('/').some(part => part === 'app_settings')) {
  $.ajax({
    type: 'GET',
    url: '/leave_types',
    dataType: 'JSON',
    success(response) {
      $('#leaves-settings').html(response.html);
      initialize_datepicker();
      $('#leave-types-table').dataTable();
    }
  });
}

const render_leaves_table = () => {
  $('#leaves-table').DataTable().clear().destroy();
  $('#leaves-table').DataTable({
    fixedHeader: true,
    processing: true,
    serverSide: true,
    ajax: {
      'type': 'GET',
      'url': '/leaves',
      'dataType': 'json',
      'data': (data) => {
        data.page = parseInt($('#leaves-table').DataTable().page.info().page) + 1
        data.per_page = $('#leaves-table').DataTable().page.info().length
        data.search = data.search.value
        data.order = $('#leaves-table').DataTable().order()[0][0]
        data.direction = $('#leaves-table').DataTable().order()[0][1]
        data.leave_type_id = $('#leave-type-filter').val()
        data.status = $('#leave-status-filter').val()
        data.payroll_status = $('#leave-payroll-status-filter').val()
        data.business_unit_id = $('#select-business-unit-filter').val()
        data.from = $('#leaves-from-filter').val()
        data.to = $('#leaves-to-filter').val()
      }
    },
    deferRender: true,
    columnDefs: [
      {
        'targets': [5, 6],
        'createdCell': (td, cellData, rowData, row, col) => {
          $(td).addClass('text-center align-middle')
        }
      },
      {
        'targets': [0, 1, 2, 3, 4],
        'createdCell': (td, cellData, rowData, row, col) => {
          $(td).addClass('align-middle')
        }
      },
    ]
  });
}

const initialize_datepicker = () => {
  $('.month-year-datepicker').datetimepicker({
    format: "MMM, YYYY",
      viewMode: "months",
      useCurrent: true,
      minDate: $('.month-year-datepicker').data('min-date'),
      maxDate: $('.month-year-datepicker').data('max-date'),
      icons: {
        previous: "fas fa-chevron-left",
        next: "fas fa-chevron-right",
      }
  })
}

const render_leave_factor_form = (dropdown) => {
  let selected_leave_type = dropdown.val()
  if (selected_leave_type == ''){
    $('.compensation-type-field').addClass('d-none')
    $('.leave-factor-form').addClass('d-none')
  }
  else{
    $.ajax({
      type: 'GET',
      url: `/leave_types/${selected_leave_type}`,
      dataType: 'script',
    });
  }
}

const refresh_leave_types_table = () =>{
  $.ajax({
    type: 'GET',
    url: `/leave_types`,
    dataType: 'script'
  });
}
