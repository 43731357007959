document.addEventListener('DOMContentLoaded', () => {
  if (window.location.pathname == '/final_settlements') {
    renderFinalSettlementSheet();
    $('#final-settlement-salary-month').select2();
    $('#final-settlement-sheet_filter').find('input')[0].type = 'text'
    $('#select_final_settlement_month').datetimepicker({
      format: window.MONTH_YEAR_FORMAT,
      viewMode: 'months',
      date: moment($('#select_final_settlement_month').data('default')),
      maxDate: moment($('#select_final_settlement_month').data('max-month')),
      minDate: moment($('#select_final_settlement_month').data('min-month')),
      icons: {
        previous: "fas fa-chevron-left",
        next: "fas fa-chevron-right",
      }
    });
  }
});

$(document).on('click', '.salary-sheet-actions-button', (e) => {
  $('.approved-or-rejected-toggle').bootstrapToggle({
    on: 'Approve',
    off: 'Reject',
    width: '100%',
    size: 'small',
    height: 'match-parent',
    style: 'rounded',
    onstyle: 'success',
    offstyle: 'danger'
  });
})

$(document).on('click', '#clear_employee_btn', (e) => {
  $('.pf_pay_toggle').bootstrapToggle({
    on: 'With PF',
    off: 'Without PF',
    width: '100%',
    size: 'small',
    height: 'match-parent',
    style: 'rounded'
  });
})

$(document).on('change', '.pf_pay_toggle', (e) => {
  let employee_id = e.target.closest('.pf_status_toggle').getAttribute('employee_id');
  if($(e.target).prop('checked')){
    $(e.target).val('1')
    $(`#employee-${employee_id}-pf-note`).removeClass('d-none');
  } else {
    $(e.target).val('0')
    $(`#employee-${employee_id}-pf-note`).addClass('d-none');
  }
});

$(document).on('change', '#final-settlement-salary-month', (e) => {
  renderFinalSettlementSheet();
  let currentSalaryMonthYear = $('#final-settlement-sheet-button')[0].getAttribute('salary-month-year');
  let selectedValue = $(e.target).find("option:selected").text();
  $('#generate-final-settlement-sheet-btn')[0].style.visibility = (currentSalaryMonthYear === selectedValue) ? 'visible' : 'hidden';
});

$(document).on('change', '#select-legends-final-settlement-sheet, #final-settlement-status-filter, #select-business-unit-filter', (e) => { renderFinalSettlementSheet(); });

$(document).on('click', '#final-settlement-sheet-row', (e) => {
  if ($('.modal').css('display') == 'block') { return }
  $('.side-pane').addClass('display-center')
  $('.side-pane').css('width', '85%')
  $('.side-pane-layer').css('display', 'block')
  $('body').css('overflow', 'hidden')
  $.ajax({
    type: 'GET',
    url: `/final_settlements/${$(e.target.parentElement).attr('data')}`,
    dataType: 'JSON',
    success(response) {
      $('.side-pane').removeClass('display-center');
      $('.side-pane').html(response.html);
      document.querySelectorAll('.nav-tabs').forEach( e => { e.firstChild.classList.add('active') });
      document.querySelectorAll('.tab-content').forEach( e => { e.firstChild.classList.add('show', 'active') });
    }
  });
})

$(document).on('click', '#final-settlement-salary-generations-btn', (e) => {
  e.stopPropagation();
  $('#final-settlement-salary-generations-btn').prop('disabled', true);
  const originalHtml = $(e.currentTarget).html();
  let employee_salary_id = $(e.currentTarget).attr('data');
  $(e.currentTarget).html('<i class="fa fa-spinner fa-spin"></i> Loading...');
  $.ajax({
    type: 'PATCH',
    url: `/employee_salaries/operations/${employee_salary_id}/generate`,
    data: { salary_sheet_type: 'final-settlement-sheet', salary_entities: 'all' },
    success: () => {
      $(e.currentTarget).html(originalHtml);
      redirectToFinalSettlements();
    },
    error: () => {
      $(e.currentTarget).html(originalHtml);
      redirectToFinalSettlements();
    }
  });
});

$(document).on('click', '#final-settlement-salary-reveiwal-btn', (e) => {
  e.stopPropagation();
  let employee_salary_id = $(e.target).closest('.dropdown-menu').attr('data');
  $.ajax({
    type: 'PUT',
    url: `/final_settlements/${employee_salary_id}`,
    success: () => redirectToFinalSettlements(),
    error: () => redirectToFinalSettlements()
  });
});

$(document).on('ajax:error ajax:success', '.add-review-form', (e) => {
  if (window.location.pathname == '/final_settlements') {
    redirectToFinalSettlements();
  }
});

const renderFinalSettlementSheet = () => {
  $('#final-settlement-sheet').DataTable().clear().destroy();
  $('#final-settlement-sheet').DataTable({
    lengthMenu: [[10, 50, 100, 500], [10, 50, 100, 500]],
    pageLength: 50,
    bDestroy: true,
    fixedHeader: true,
    processing: true,
    serverSide: true,
    ajax: {
      'type': 'GET',
      'url': '/final_settlements',
      'dataType': 'json',
      'data': (data) => {
        data.page = parseInt($('#final-settlement-sheet').DataTable().page.info().page) + 1
        data.per_page = $('#final-settlement-sheet').DataTable().page.info().length
        data.search = { text: data.search.value,
                        business_unit: $('#select-business-unit-filter').val(),
                        filters: $('#select-legends-final-settlement-sheet').val(),
                        status: $('#final-settlement-status-filter').val() }
        data.order = $('#final-settlement-sheet').DataTable().order()[0][0]
        data.direction = $('#final-settlement-sheet').DataTable().order()[0][1]
        data.month_year = $("#final-settlement-salary-month").find("option:selected").text();
      }
    },
    "drawCallback": () => {
      if($('#final-settlement-sheet').DataTable().rows().count()){
        $('#final-settlement-sheet tr:last').attr('id','net-total-row');
      }
    },
    deferRender: true,
    columnDefs: [
      {
        'targets': 0,
        'createdCell': (td, cellData, rowData, row, col) => {
          $(td).addClass('text-center')
          if(rowData[18].approved){
            $(td).append("<i class='fas fa-sm fa-lock px-2 d-block text-center'></i>")
          }
        }
      },
      {
        'targets': 1,
        'createdCell': (td, cellData, rowData, row, col) => {
          if(rowData[18]){
            table_row = $(td).closest('tr')
            table_column = $(td)
            table_row.addClass(rowData[18].row_color_code)
            table_row.attr('id', 'final-settlement-sheet-row')
            table_column.addClass('employee-profile-page text-break')
            table_row.addClass('cursor-pointer')
            table_row.attr('data', rowData[18].emp_salary_id)
            table_column.attr('onClick', `window.open('/employees/${rowData[18].employee_external_id}')`),
            table_column.attr({
              'data-toggle': 'tooltip',
              'data-placement': 'right',
              'title': `${rowData[18].employee_designation}`
            })
          }
        }
      }
    ],
    "rowCallback": (row, data, dataIndex) => {
      $(row).find('.salary-sheet-actions-dropdown').closest('td').addClass('disable-propagation')
    }
  });
  debounceSearch('#final-settlement-sheet')
}

const redirectToFinalSettlements = () => { window.location.href = '/final_settlements'; }
