import consumer from "./consumer"

jQuery(document).ready(function(){
  consumer.subscriptions.create("PayrollSheetErrorsChannel", {
    received: (data) => {
      if (data.event == 'warning') {
        $('.salary-operation-status').addClass('d-none');
        let designations = data.designations
        $.ajax({
          type: 'GET',
          url: '/pf_percentages/new',
          data: { designations: designations }
        })
      }
      else {
        var message = ''
        if(data.event == 'salary_operation') {
          $('.salary-operation-status').remove();
          message = `Salary ${data.operation} failed because ${data.message}, Please press OK to refresh page`
        }
        else {
          $('.salary-dispatching-status').remove();
          message = `Failed to dispatch Salary because ${data.message}, Please press OK to refresh page`
        }
        setTimeout(() => {
          if (data.message) {
            if (confirm(message)) {
              location.reload();
            }
          }
        }, 200)
      }
    }
  });
});
