document.addEventListener('DOMContentLoaded', () => {
  const property = {
                    'targets': 6,
                    'defaultContent': '<button class= "editor_remove fas fa-trash-alt" type="button"></button>'
                   };
  generic_table_properties('#tax-slab-modal', property);
  $('#previous_slabs_toggle').bootstrapToggle({
    on: 'Copy from Previous Year Slabs',
    off: 'Create new Slabs',
    width: '15%',
    size: 'medium'
  });
});

var generic_table_properties = (table_id, property) => {
 $(table_id).dataTable({
    'paging': false,
    'searching': false,
    'order': [],
    'info': false,
    'columnDefs': [
      {
        'targets': 5,
        'visible': false,
      },
      property
    ],
    'language': {
      'emptyTable': 'No Slabs Entered'
    }
  }).width('100%');
}

const add_new_row = (upper, lower, fixed, percent, year, start_year) => {
  if ([upper, lower, fixed, percent].includes('')) {
    return append_errors_inside_modal(['Please select all the fields'], '', $('#ajax_errors'));
  }
  if ($('#percent').val() == '' || $('#percent').val() < 0 || $('#percent').val() > 100){
    return append_errors_inside_modal(['Tax percentage should be between 0 and 100 both inclusive'], '', $('#ajax_errors'));
  }
  let flag = true;
  const table = get_table('#tax-slab-modal');
  table.rows().data().each((data) => {
    const lower_slab = parseInt(data[0]);
    const upper_slab = parseInt(data[1]);
    if (((lower >= lower_slab) && (lower <= upper_slab)) || ((upper >= lower_slab) && (upper <= upper_slab)) ||
       ((lower_slab >= lower) && (lower_slab <= upper)) || (((upper_slab >= lower) && (upper_slab <= upper)) && flag)) {
      flag = false;
      return append_errors_inside_modal(['Slabs are Overlapping'], '', $('#ajax_errors'));
    }
  });
  if (flag) { update_table_rows(table, upper, lower, fixed, percent, year, start_year); }
};

var update_table_rows = (table, upper, lower, fixed, percent, year, start_year) => {
  const count = table.rows().count() + 1;
  table.row.add([lower, upper, fixed, percent, year, start_year]).draw();
  resetSlabsfields();
  $('#lower').val(Number(parseInt(upper.replaceAll(',', '')) + 1).toLocaleString());
  table.order([0, 'asc']).draw();
  $('#ajax_errors').html('');
  $('#ajax_errors').addClass('d-none');
};

$(document).on('click', '#add-slab', () => {
  const upper = $('#upper').val();
  const lower = $('#lower').val();
  const fixed = $('#fixed').val();
  const percent = `${parseFloat($('#percent').val()).toFixed(2)} %`;
  const slab_duration = $('#year').val();
  const start_year = parseInt($('#start-year').val());
  add_new_row(upper, lower, fixed, percent, slab_duration, start_year);
});

var resetSlabsfields = e => $('#upper, #lower, #fixed, #percent').val('');

const show_error = (row, table) => {
  const node = table.row(row-1).node();
  $(node).addClass('text-danger');
};

const saveSlabs = (slabs_data, table) => {
 $.ajax({
    type: 'POST',
    url: 'tax_slabs',
    data: { slabs_data },
    success(data) {
      window.location.href = 'tax_slabs';
    },
    error(error) {
      append_errors_inside_modal([error.responseJSON.message], '', $('#ajax_errors'));
      resetSlabsfields();
      show_error(error.responseJSON.row, table);
    }
  });
}

$(document).on('click', '#save', () => {
  const slabs_data = [];
  const option = $('#previous_slabs_toggle').prop('checked');
  const table_id = option ? '#prev-slab-modal' : '#tax-slab-modal';
  const table = get_table(table_id);
  table.rows().data().each(data => slabs_data.push(data));
  saveSlabs(slabs_data, table);
});

$(document).on('click', '.editor_remove', (e) => {
  get_table('#tax-slab-modal').row($(e.target).closest('tr')).remove().draw();
});

$(document).on('change', '#previous_slabs_toggle', (e) => {
  const option = $(e.target).prop('checked');
  if (option) {
    $('.previous-tax-slabs-container').removeClass('d-none');
    $('.previous-tax-slabs').select2({ theme: 'bootstrap4'});
    displaySlabs($('.previous-tax-slabs').find(":selected").val())
  } else {
    $('.previous-tax-slabs-container').addClass('d-none');
    $('.auto-add').html('');
    $('.manual-add').removeClass('d-none');
  }
});

$(document).on('change', '.previous-tax-slabs', () => {
  displaySlabs($('.previous-tax-slabs').find(":selected").val())
});

const displaySlabs = (tax_slab_year) =>{
  $.ajax({
    type: 'GET',
    url: 'tax_slabs',
    data: { previous_slabs_year: tax_slab_year },
    success(data) {
      $('.manual-add').addClass('d-none');
      $('.auto-add').html(data.prev_slabs);
      generic_table_properties('#prev-slab-modal', '');
    },
    error(error) {
      append_errors_inside_modal(error.responseJSON.message, '', $('#ajax_errors'));
    }
  });
}

var get_table  = id => $(id).DataTable();
