import consumer from "./consumer"

consumer.subscriptions.create("WebpushNotificationChannel", {
  received: (data) => {
    if (Notification.permission === 'granted') {
      let title = 'Payroll Push Notification'
      let options = { body: data.message }
      new Notification(title, options)
    }
  }
});
