document.addEventListener('DOMContentLoaded', () => {
  filter_pf_table();
  initializeDatePicker();
  $('#pf-table').DataTable({
    fixedHeader: true,
    processing: true,
    serverSide: true,
    ajax: {
      'type': 'get',
      'url': '/pf_wallets',
      'dataType': 'json',
      'data': (data) => {
        data.page = parseInt($('#pf-table').DataTable().page.info().page) + 1
        data.per_page = $('#pf-table').DataTable().page.info().length
        data.search = data.search.value
        data.order = $('#pf-table').DataTable().order()[0][0]
        data.direction = $('#pf-table').DataTable().order()[0][1]
      }
    },
    "drawCallback": () => {
      if($('#pf-table').DataTable().rows().count()){
        $('#pf-table tr:last').attr('id','net-total-row');
      }
    },
    deferRender: true,
    columnDefs: [
      {
        "bSortable": false,
        "aTargets": [ 6, 8 ]
      }
    ]
  });
});

$(document).on('change.select2', '#pf-filter, #select-business-unit-filter', function() { filter_pf_table() });
const filter_pf_table = () => {
  let pf_filter_type = $('#pf-filter').val();
  let business_unit_id = $('#select-business-unit-filter').val();
  if (window.location.pathname.split('/')[1] == 'pf_wallets') {
    $.ajax({
      type: 'GET',
      url: '/pf_wallets',
      dataType: 'script',
      data: { pf_filter_type: pf_filter_type, business_unit_id: business_unit_id }
    });
  }
}

$(document).on('change', '.pf-maturity-period-toggle', (e) => {
  let pf_mp_field = $(e.target).closest('.app-setting-form').find('.pf-maturity-period-toggle')
  pf_mp_field.prop('checked') ? $('.pf-for-partials').attr('disabled', false) : $('.pf-for-partials').attr('disabled', true)
});

$(document).on('change', '#pf_wallets_csv', (e) => {
  const fileName = $(e.target).val().split("\\").pop();
  if (fileName.replace(/^.*\./, '') === 'csv') {
    $(e.target).siblings(".custom-file-label").addClass("selected").html(fileName);
    $(e.target).parent().find('p').remove();
    $('#import-provident-funds-btn').attr("disabled", false);
  } else {
    $('#import-provident-funds-btn').attr("disabled", true);
    $(e.target).parent().append("<p id='pf_csv_errors' style='color: red'>Please choose csv file.</p>");
    setTimeout(() => {
      $('#import-provident-funds-btn').attr("disabled", true);
      $('#error').remove();
    }, 5000);
    e.preventDefault();
  }
});


$(document).on('click', '.recalculate-pf', (e) => {
  let employee_id = $(e.currentTarget).attr('id').split('-')[2]
  $.ajax({
    type: 'POST',
    url: '/pf_wallets/recalculate_pf_wallet',
    data: { employee_id: employee_id },
    success(response) {
      window.location.href = '/pf_wallets';
    },
    error(error) {
      window.location.href = '/pf_wallets';
    }
  });
});

const initializeDatePicker = () => {
  $('#contribution-month-dropdown').datetimepicker({
    format: "MMM, YYYY",
      viewMode: "months",
      minDate: moment($('#contribution-month-dropdown').data('min-month')),
      maxDate:  moment($('#contribution-month-dropdown').data('max-month')),
      icons: {
        previous: "fas fa-chevron-left",
        next: "fas fa-chevron-right",
      }
  })
}

$(document).on('dp.change', '#contribution-month-dropdown', e => {
  let contributionType = $('.contribution-type')
  contributionType.attr('disabled', false);
  if (contributionType.val() != '') {
    showMonthlyContribution($(e.target).val(), contributionType.val())
  }
})

$(document).on('change', '.contribution-type', e => {
  showMonthlyContribution($('#contribution-month-dropdown').val(), $(e.target).val())
})

const showMonthlyContribution = (contributionMonth, contributionType) => {
  $.ajax({
    'type': 'get',
    'dataType': 'json',
    'url': '/pf_wallets/monthly_insights',
    'data': { contribution_type: contributionType, contribution_month: contributionMonth },
    success(response) {
      if (response.monthly_contribution != null) {
        $('.monthly-contribution').html(`Monthly Contribution: ${response.monthly_contribution}`)
      } else {
        $('.monthly-contribution').html(``)
      }
    }
  });
}
