import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'packs/application.scss'
import 'datatables.net-dt/css/jquery.dataTables.css'
import '@fortawesome/fontawesome-free/css/all.css'
import "controllers"
import "chartkick/chart.js"

import { Turbo } from "@hotwired/turbo-rails"
import moment from 'moment'
import select2 from 'select2'

window.moment = moment

require('popper.js')
require('@rails/ujs').start()
require('@rails/activestorage').start()

require('bootstrap')
require('datatables.net')
require('datatables.net-dt')
require('datatables.net-fixedheader')

require('@fortawesome/fontawesome-free')

require('bootstrap4-toggle')


require('channels')
require('src/allowances')
require('src/common')
require('src/claim')
require('src/dashboard')
require('src/pf_percentages')
require('src/employees')
require('src/loans')
require('src/provident_funds')
require('src/salary_logs')
require('src/dollar_rates')
require('src/hr_requests')
require('src/tax_slabs')
require('src/payroll_sheet')
require('src/designations_groups')
require('src/vendors/jquery.periodical_updater')
require('src/vendors/jquery.updater')
require('src/custom_unit')
require('src/leaves_settings')
require('src/app_settings')
require('src/foreign_salaries')
require('src/users')
require('src/additional_payments')
require('src/data_insights')
require('src/groups')
require('src/final_settlement_sheet')

Turbo.session.drive = false

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

require("trix")
require("@rails/actiontext")
